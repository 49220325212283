import React, { useEffect } from "react"
import CriteresSelection from "../components/sections/criteresSelection"
import Layout from "../components/layout"
import { ScrollInfo } from "../components/ui/scrollInfo"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useStore } from "../@core/scenes/mainStore"
import { Helmet } from "react-helmet"


export default function CriteresSelectionPage() {

  useEffect(()=> {
    useStore.setState({theme: "white"})
  })

  // Scroll Trigger
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    // Critères de sélection
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-criteres",
        pin: true,
        scrub: 1,
        start: "top top",
        end: "+=5000",
        toggleActions: "play pause play reset",
        // markers: true
      },
    })
    tl.fromTo(
      ".section-criteres-top",
      {
        opacity: 1,
        display: 'block',
      },
      {
        opacity: 0,
        display: 'none',
        duration: 0.5,
      },
    )
    // cercle gradient
    .fromTo(".circular-gradient", {
      opacity: 0,
    }, {
      opacity: 0.2,
    })
    // disparition du scroll info
    .fromTo(".scroll-info",
    {
      opacity:0,
      transform: "translateY(-5px)",
      display: "none"
    },
    {
      opacity:1,
      transform: "translateY(0px)",
      display: "block"
    },
    "-=1")
    // apparition UI critères
    .fromTo(
      ".criteres-ui-list",
      {
        opacity: 0,
        transform: "translateX(100px)",
      },
      {
        opacity: 1,
        transform: "translateX(0px)",
        duration: 2,
      },
      "-=1"
    )
  })

  return (
    <Layout localClass="criteres-selections-page" showPointClouds={false}>
      <Helmet>
          <title>Critères de sélection</title>
      </Helmet>
      <CriteresSelection />
      <ScrollInfo classLocal="white" />
    </Layout>
  )
}
